<template>
    <footer>
      <section class="details">
            <p>
                Net paid/received for Q1 2021
            </p>
            <p>
                S&P 500 and non-S&P 500 Stocks
            </p>
            <p>
                All Order Types
            </p>
            <p>
                Options not included
            </p>
        </section>
        <section class="game-over">
            <h4>Game Over</h4>
        </section>
        <section class="credits">
            <h5>Credits</h5>
            <p>
                <a href="https://www.schwab.com/legal/order-routing-1" target="_blank">Charles Schwab</a>
            </p>
            <p>
                <a href="https://us.etrade.com/l/quarterly-order-routing-report" target="_blank">E*Trade</a>
            </p>
            <p>
                <a href="https://clearingcustody.fidelity.com/app/item/RD_13569_21696/sec-rule-606.html" target="_blank">Fidelity</a>
            </p>
            <p>
                <a href="https://www.interactivebrokers.com/en/index.php?f=563" target="_blank">Interactive Brokers</a>
            </p>
            <p>
                <a href="https://robinhood.com/us/en/about/legal/" target="_blank">Robinhood</a>
            </p>
            <p>
                <a href="https://www.tdameritrade.com/disclosure.html" target="_blank">TD Ameritrade</a>
            </p>
        </section>
        <section class="social"></section>
    </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {},
};
</script>
