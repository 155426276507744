<template>
    <section class="winner">
    <h3 class="market-maker">Congratulations Citadel!!!</h3>
    <p>
        You spent the most money on retail customers' order flow!
        I wonder what you did with it?
    </p>
    </section>
</template>

<script>
export default {
  name: 'Winner',
  props: {},
};
</script>
