<template>
    <section class="winner">
    <h3 class="td-ameritrade">Congratulations TD Ameritrade!!!</h3>
    <p>
        You made the most money selling
        your customers' orders to
        high&nbsp;frequency trading firms!
    </p>
    </section>
</template>

<script>
export default {
  name: 'Winner',
  props: {},
};
</script>
